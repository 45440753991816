@import "../constants/scss/vars.scss";

.button-block {
  background: white;
  margin: 16px 0;
  height: 72px;
  padding: 10px;
  border-radius: 72px;
  color: $dark-blue;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;


  a,
  span {
    color: $dark-blue;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }


  &.button-black {
    background: $dark-blue;
    border: 1px solid white;

    a,
    span {
      color: white
    }
  }

  &.button-mode-manual {
    a, span {
      color: $orange;
      font-weight: 800;
    }

  }

  &.button-mode-auto {
    a, span {
      color: $green;
      font-weight: 800;
    }
  }

  &.button-disabled {
    background: $dark-blue;
    opacity: 0.2;
    cursor: default;

    a,
    span {
      color: white;
    }
  }

  &.button-overflow {
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100px;
    }
  }

  &.button-invisible {
    background: transparent;
    border: none;

    a,
    span {
      color: $dark-blue;
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &.black-border {
    border: 1px solid $dark-blue;
  }

  img {
    max-height: 20px;
    max-width: 20px;
  }

  &.icon-reverse {
    flex-direction: row-reverse;

    img {
      margin: 0 20px 0 0;
    }

    &.small-margin {
      img {
        margin: 0 10px 0 0;
      }
    }

    &.xsmall-margin {
      img {
        margin: 0 8px 0 0;
      }
    }
  }

}
