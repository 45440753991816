@import "../constants/scss/vars.scss";


@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/fonts/CeraPro-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/fonts/CeraPro-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root,
.App,
.App-header {
  height: 100%;
}

body {
  background: #F7F7F7;
  color: white;
  margin: 0px;
  font-family: "Open Sans";
}

.status-badge {
  background: #FF307A;
  border-radius: .25rem;
  height: .5rem;
  width: .5rem;

  &.green {
    background: #43EA8D;
  }

  &.mode-manual {
    background: $orange;
  }

  &.mode-auto {
    background: $green;
  }
}

button {
  outline: none;
  border: none;
}
