@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

$light-blue: #57FFF5;
$dark-blue: #1F2942;
$light-gray: #F7F7F7;
$gray: #E5E5E5;
$dark-gray: grey;
$green: #43EA8D;
$red: #FF307A;
$orange: #F6A728;
$pale-red: #FDF0F1;
$light-gray2: #F6F7F8;
