@import "../constants/scss/vars.scss";

.plume-title-block {
  margin: 0 32px;

  .plume-title {
    margin: 0px 0 16px;
    font-family: "Cera Pro";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: $dark-blue;

    img {
      margin-left: 10px;
      max-width: 30px;
      max-height: 30px;
    }
  }

  .plume-text {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: $dark-blue;
  }



  &.title-white {

    .plume-title,
    .plume-text {
      color: #FFFFFF;
    }
  }

  &.title-margin-top {
    margin-top: 64px;
  }

  &.no-margin {
    margin: 0;
  }
}

.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .home-logo {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.account-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: $light-gray;
  color: $dark-blue;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 120px 90px;
}

.account-form {
  margin-top: 64px;

  input {
    border: 2px solid $light-gray;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .button-block {
    margin-top: 40px;
    width: 100%;
  }
}

.account-title {
  margin: 48px 0 16px;
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: $dark-blue;
  display: flex;
  flex-direction: row;
  align-content: center;

}

.account-text {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: $dark-blue;
  margin-bottom: 40px;
}

.account-logo {
  margin: 50px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    max-height: 40px;
    max-width: 40px;
  }
}

.account-how {
  margin: 108px auto 0;
  display: flex;
  justify-content: center;

  img {
    width: calc(100% - 66px);
    height: auto;
  }
}

.account-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.account-splash {
  min-width: 50%;
  min-height: 100%;
  position: relative;
}
